// extracted by mini-css-extract-plugin
export var carouselContainer = "K_wN d_w d_H d_bf d_Z";
export var carouselContainerCards = "K_wP K_wN d_w d_H d_bf d_Z";
export var carouselContainerSides = "K_wQ d_w d_H d_Z";
export var prevCarouselItem = "K_wR d_w d_H d_0 d_k";
export var prevCarouselItemL = "K_wS K_wR d_w d_H d_0 d_k";
export var moveInFromLeft = "K_wT";
export var prevCarouselItemR = "K_wV K_wR d_w d_H d_0 d_k";
export var moveInFromRight = "K_wW";
export var selectedCarouselItem = "K_wX d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "K_wY K_wX d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "K_wZ K_wX d_w d_H d_Z d_bf";
export var nextCarouselItem = "K_w0 d_w d_H d_0 d_k";
export var nextCarouselItemL = "K_w1 K_w0 d_w d_H d_0 d_k";
export var nextCarouselItemR = "K_w2 K_w0 d_w d_H d_0 d_k";
export var arrowContainer = "K_w3 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "K_w4 K_w3 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "K_w5 K_w4 K_w3 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "K_w6 K_w3 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "K_w7";
export var nextArrowContainerHidden = "K_w8 K_w6 K_w3 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "K_kG d_0";
export var prevArrow = "K_w9 K_kG d_0";
export var nextArrow = "K_xb K_kG d_0";
export var carouselIndicatorContainer = "K_xc d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "K_xd d_w d_bz d_bF";
export var carouselText = "K_xf d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "K_xg K_xf d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "K_xh d_b7";
export var carouselIndicator = "K_xj K_xh d_b7";
export var carouselIndicatorSelected = "K_xk K_xh d_b7";
export var arrowsContainerTopRight = "K_xl d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "K_xm d_0 d_bl d_bC";
export var arrowsContainerSides = "K_xn d_0 d_bl d_bC";
export var smallArrowsBase = "K_xp d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "K_xq K_xp d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "K_xr K_xq K_xp d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "K_xs K_xp d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "K_xt K_xs K_xp d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "K_p9";
export var iconSmall = "K_xv";
export var multipleWrapper = "K_xw d_bC d_bF d_bf";
export var multipleImage = "K_xx d_bC";
export var sidesPrevContainer = "K_xy K_xq K_xp d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "K_xz K_xq K_xp d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";