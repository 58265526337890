// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_s0 d_fp d_bG d_dv";
export var alignLeft = "v_qL d_fp d_bG d_dv";
export var alignDiscCenter = "v_s1 d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_s2 d_fr d_bH d_dx";
export var alignRight = "v_qM d_fr d_bH d_dx";
export var footerContainer = "v_s3 d_dW d_bW";
export var footerContainerFull = "v_s4 d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_s5 d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_s6 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_s7 d_bz d_bJ d_bN d_bL";
export var wide = "v_s8 d_cy";
export var right = "v_s9 d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_tb d_bC d_bP d_bJ";
export var badgeContainer = "v_tc d_bz d_bH d_bP";
export var badge = "v_td";
export var padding = "v_tf d_c7";
export var end = "v_tg d_bH";
export var linkWrapper = "v_th d_dB";
export var link = "v_mC d_dB";
export var colWrapper = "v_tj d_cx";
export var consent = "v_h d_h d_bC d_bP";
export var disclaimer = "v_tk d_bz d_bJ";
export var media = "v_tl d_bx d_dy";
export var itemRight = "v_tm";
export var itemLeft = "v_tn";
export var itemCenter = "v_tp";
export var exceptionWeight = "v_tq F_vN";