// extracted by mini-css-extract-plugin
export var dark = "r_rx";
export var darkcookie = "r_ry";
export var tintedcookie = "r_rz";
export var regularcookie = "r_rB";
export var darkbase = "r_rC";
export var tintedbase = "r_rD";
export var regularbase = "r_rF";
export var darkraw = "r_rG";
export var tintedraw = "r_rH";
export var regularraw = "r_rJ";
export var darkchick = "r_rK";
export var tintedchick = "r_rL";
export var regularchick = "r_rM";
export var darkherbarium = "r_rN";
export var tintedherbarium = "r_rP";
export var regularherbarium = "r_rQ";
export var darkholiday = "r_rR";
export var tintedholiday = "r_rS";
export var regularholiday = "r_rT";
export var darkoffline = "r_rV";
export var tintedoffline = "r_rW";
export var regularoffline = "r_rX";
export var darkorchid = "r_rY";
export var tintedorchid = "r_rZ";
export var regularorchid = "r_r0";
export var darkpro = "r_r1";
export var tintedpro = "r_r2";
export var regularpro = "r_r3";
export var darkrose = "r_r4";
export var tintedrose = "r_r5";
export var regularrose = "r_r6";
export var darktimes = "r_r7";
export var tintedtimes = "r_r8";
export var regulartimes = "r_r9";
export var darkwagon = "r_sb";
export var tintedwagon = "r_sc";
export var regularwagon = "r_sd";