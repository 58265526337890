// extracted by mini-css-extract-plugin
export var lbContainerOuter = "H_wx";
export var lbContainerInner = "H_wy";
export var movingForwards = "H_wz";
export var movingForwardsOther = "H_wB";
export var movingBackwards = "H_wC";
export var movingBackwardsOther = "H_wD";
export var lbImage = "H_wF";
export var lbOtherImage = "H_wG";
export var prevButton = "H_wH";
export var nextButton = "H_wJ";
export var closing = "H_wK";
export var appear = "H_wL";