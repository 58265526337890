// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pB";
export var searchResult = "m_qh m_pB";
export var line = "m_fk d_fk d_cY";
export var articleTitle = "m_qj d_cq";
export var articleText = "m_pC d_cs";
export var authorImage = "m_ln d_ln d_b7 d_Z d_ct";
export var authorText = "m_p2 d_ch d_ct";
export var textCenter = "m_dw d_dw";
export var searchWrapper = "m_qk d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "m_ql d_cF d_w d_b4";
export var searchBtn = "m_qm d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "m_qc d_cr";
export var elementWrapper = "m_my d_cl";
export var select = "m_qn d_w d_b4";
export var articleImageWrapper = "m_pT d_cs d_Z d_V";
export var articleImageWrapperIcon = "m_pV d_cs d_Z d_V";
export var articleImageWrapperColumns = "m_qp d_cs d_fh d_Z";
export var contentPadding = "m_qq d_cS";
export var otherPadding = "m_qr d_cM";
export var paginationWrapper = "m_qs";
export var pagination = "m_qt";
export var disabledPagination = "m_qv";
export var breakMe = "m_qw";
export var activePage = "m_qx";
export var next = "m_qy";
export var pages = "m_qz";
export var searchTitle = "m_qB d_cr";
export var categoryTitle = "m_qC d_cr d_Z";
export var searchText = "m_qD d_ct";
export var bold = "m_qF";
export var field = "m_qG";
export var dateTag = "m_pL d_l d_cr d_dq";
export var icon1 = "m_pM d_cP";
export var icon2 = "m_pN d_cP d_cJ";
export var tagBtn = "m_pP d_b1 d_cD d_dp d_dB";
export var linksWrapper = "m_p5 d_ch d_dw";
export var header = "m_pD d_w d_Z";
export var headerInner = "m_qH";
export var headerImage = "m_qJ d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "m_p1 d_cY";
export var resultImage = "m_qK";