// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "s_sf d_bD";
export var datasheetRowWrapper = "s_lt d_lt d_bK";
export var datasheetLeftWrapper = "s_sg d_bz d_bP";
export var datasheetWrapperFull = "s_sh d_cD";
export var datasheetWrapperFullLeft = "s_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "s_mv d_lv";
export var contentWrapperCenter = "s_sj d_lz";
export var datasheetLeftWrapperCenter = "s_sk d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "s_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "s_sl d_lw";
export var datasheetFooter = "s_ly d_ly";
export var alignLeft = "s_qL d_dv";
export var alignCenter = "s_bP d_dw";
export var alignRight = "s_qM d_dx";
export var datasheetInnerWrapperNewLeft = "s_sm d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "s_sn d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "s_sp d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "s_sq d_bK";
export var datasheetSubtitle = "s_sr d_cx";
export var tableRow = "s_ss";
export var cell = "s_st";
export var tableRowWrapper = "s_sv d_w d_dv";
export var tableHeadingCell = "s_sw";
export var tableHeading = "s_sx";
export var tableRowStriped = "s_sy";
export var tableRowHeading = "s_sz";
export var dataTable = "s_sB d_w";
export var imageWrapper = "s_sC d_fg d_Z";
export var imageWrapperFull = "s_sD d_H d_w d_bf d_Z";
export var imageWrapperIcon = "s_sF";
export var titleMargin = "s_sG d_cs";
export var datasheetInnerInnerWrapper = "s_sH d_w";
export var hasLabels = "s_sJ";
export var label = "s_sK";
export var SmallSmall = "s_sL F_sL F_vl F_vJ";
export var SmallNormal = "s_sM F_sM F_vl F_vK";
export var SmallLarge = "s_sN F_sN F_vl F_vG";